import React from "react";
import { graphql } from "gatsby";
import { keysToCamel } from "@utils";
import CurriculumSingle from "../../pageTemplates/CurriculumSingle/CurriculumSingle";
import resolveEmbeddedDocument from "../dataResolvers/resolveEmbeddedDocument";
import { useAllCollectionsData } from "@staticQueries";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveSlices from "../dataResolvers/resolveSlices";
import { t } from "@translations";

/* eslint react/prop-types: 0 */

const dataResolver = ({ prismicAssessments }) => {
  const { uid, type, data } = prismicAssessments;
  const {
    cor_question,
    disciplines,
    delivery,
    related_materials,
    assessment_link,
    rubric_link,
    body,
    ...rest
  } = data;

  const collections = useAllCollectionsData()
    .filter(({ materials }) => {
      return materials.find(material => material.uid === uid);
    })
    .map(({ uid: _uid, title, illustration }) => ({
      uid: _uid,
      title,
      illustration,
    }));

  const resolved = {
    data: {
      ...rest,
      uid,
      type,
      corQuestion: resolveEmbeddedDocument(cor_question) || {},
      disciplines: resolveEmbeddedDocument(disciplines) || {},
      delivery,
      downloads: [
        {
          level: null,
          items: [
            {
              title:
                delivery === "Digital"
                  ? t("Make a copy of assessment")
                  : t("View assessment"),
              link: assessment_link,
            },
            {
              title: "View rubric",
              link: rubric_link,
            },
          ],
        },
      ],
      collections,
      body: resolveSlices(body),
      relatedMaterials: related_materials
        .filter(material => material.material.uid && material.material.type)
        .map(material => ({
          uid: material.material.uid,
          type: material.material.type,
        })),
    },
  };

  return keysToCamel(resolved);
};

const AssessmentQuery = ({ data: _data, location }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <CurriculumSingle {...data} location={location} />;
};

export default AssessmentQuery;

export const query = graphql`
  query($uid: String!) {
    prismicAssessments(uid: { eq: $uid }) {
      id
      uid
      type
      data {
        title
        delivery
        illustration {
          text
        }
        summary {
          text
        }
        related_materials {
          material {
            uid
            type
          }
        }
        body {
          ... on PrismicAssessmentsBodyCopy {
            id
            slice_type
            primary {
              rich_text {
                html
              }
            }
          }
          ... on PrismicAssessmentsBodyImages {
            id
            slice_type
            items {
              image {
                url
                dimensions {
                  width
                  height
                }
                alt
              }
              focal_point_x
              focal_point_y
              filter
              caption {
                html
              }
            }
          }
          ... on PrismicAssessmentsBodyVideo {
            id
            slice_type
            primary {
              cta_text
              video {
                id
                uid
                document {
                  ... on PrismicVideos {
                    id
                    data {
                      youtube_video_id
                      title
                      description {
                        html
                      }
                      thumbnail {
                        focal_point_x
                        focal_point_y
                        image {
                          url
                          dimensions {
                            height
                            width
                          }
                          alt
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAssessmentsBodyParallelAssessmentCta {
            id
            slice_type
            primary {
              title
              description {
                html
              }
              assessment_link {
                id
                url
                target
              }
              rubric_link {
                id
                url
                target
              }
            }
          }
        }
        assessment_link {
          url
          target
          kind
        }
        rubric_link {
          url
          target
          kind
        }
        cor_question {
          document {
            ... on PrismicCorQuestions {
              id
              data {
                color
                title
              }
            }
          }
        }
        disciplines {
          discipline {
            document {
              ... on PrismicDisciplines {
                id
                data {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
